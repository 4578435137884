@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'Gotham';
    src: url('./fonts/Gotham-Bold.otf') format('opentype');
    font-weight: bold;
    font-display: swap;
}

@font-face {
    font-family: 'Gotham';
    src: url('./fonts/Gotham-Medium.otf') format('opentype');
    font-weight: 500;
    font-display: swap;
}

@font-face {
    font-family: 'Gotham';
    src: url('./fonts/Gotham-Book.otf') format('opentype');
    font-weight: normal;
    font-display: swap;
}

#carousel > *::-webkit-scrollbar {
    display: none;
}
